/*
* base.less
*
* 此处存放基础设置下项：
* 1. 跟字号和rem缩放比例设置；
* 2. media查询区间变量
* 3. 一些字号的设置
*
*/
.font-xs-t1 {
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 500;
}
.font-xs-t2 {
  font-size: 14px;
  line-height: 160%;
  font-weight: inherit;
  color: #9B9B9B;
  text-align: center;
}
.font_lg_t2 {
  font-size: 18px;
  line-height: 160%;
  font-weight: inherit;
  color: #9B9B9B;
  text-align: left;
}
html {
  font-size: 100px;
}
html #root {
  font-size: 14px;
}
@media (min-width: 200px) {
  html {
    font-size: 45px;
  }
}
@media (min-width: 375px) {
  html {
    font-size: 53px;
  }
}
@media (min-width: 425px) {
  html {
    font-size: 60px;
  }
}
@media (min-width: 720px) {
  html {
    font-size: 100px;
  }
}
/* public */
select,
textarea,
input,
a,
button {
  outline: none;
  border: 0;
}
select:active,
textarea:active,
input:active,
a:active,
button:active,
select:focus,
textarea:focus,
input:focus,
a:focus,
button:focus,
select:hover,
textarea:hover,
input:hover,
a:hover,
button:hover {
  outline-offset: 0;
  outline: none;
  border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
body {
  color: #565a5f;
  font-weight: 400;
  letter-spacing: 0.3px;
  font: 14px 'Hind Siliguri', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', sans-serif;
  -webkit-overflow-scrolling: touch;
}
.guru-btn-big {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 45px;
  border-radius: 4px;
}
.guru-btn-big:hover,
.guru-btn-big:focus,
.guru-btn-big.focus {
  color: #fff;
  transform: scale(1.05);
  text-decoration: none;
}
.childrenMain {
  margin-top: 42px;
}
.logo-btn {
  background-color: #f55b23;
  color: #fff;
}
.logo-btn:hover,
.logo-btn:focus,
.logo-btn.focus {
  color: #fff;
  text-decoration: none;
}
.big-margin-top {
  margin-top: 50px;
}
.small-margin-top {
  margin-top: 20px;
}
.textRight {
  text-align: right;
}
.A_link {
  position: fixed;
  left: -500px;
  width: 100px;
}
.s-alert-wrapper .s-alert-effect-scale {
  border-radius: 6px;
  padding: 1em 1.5em;
  z-index: 9999;
}
.s-alert-wrapper .s-alert-effect-scale .s-alert-close {
  right: 0;
  top: 0;
}
.modal-dialog {
  margin: 50px auto 30px;
}
@media (max-width: 768px) {
  body {
    position: relative;
    font-size: 16px;
  }
  body h1 {
    font-size: 22px;
    margin-top: 0px;
  }
  body .childrenMain {
    margin-top: 0.7rem;
  }
  body .big-margin-top {
    margin-top: 30px;
  }
  body .small-margin-top {
    margin-top: 12px;
  }
  body .modal-dialog {
    margin: 30px auto 30px;
  }
}
@media (min-width: 768px) {
  body {
    position: relative;
    font-size: 22px;
  }
  body h1 {
    font-size: 26px;
    margin-top: 0;
  }
  body .feedPage {
    background: url(/app/castbox/static/images/WEB-Background.jpg) repeat 0px 0px;
  }
}
.textLeft {
  display: inline-block;
  text-align: left;
}
.textLeft .textTwoClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.g-toast {
  top: 0.86rem;
}
.g-toast-i {
  margin-bottom: 0.24rem;
  border-radius: 4px;
}
