/*
* base.less
*
* 此处存放基础设置下项：
* 1. 跟字号和rem缩放比例设置；
* 2. media查询区间变量
* 3. 一些字号的设置
*
*/
@import './setting.less';

// VVV font of indexPage and aboutPage
.font_44_53(@line:1){
	font-size: 44px;
	line-height: 53px;
	height: @line*53px;
}
.font_40_48(@line:1){
	font-size: 40px;
	line-height: 48px;
	height: @line*48px;
}
.font_36_43(@line:1){
	font-size: 36px;
	line-height: 43px;
	height:@line*43px;
}
.font_30_36(@line:1){
	font-size: 30px;
	line-height: 36px;
	height: @line*36px;
}
.font_24_29(@line:1){
	font-size: 24px;
	line-height: 29px;
	height: @line*29px;
}
.font_28_33(@h:33px){
	font-size: 28px;
	line-height: 33px;
	height: @h;
}
.border_radius(@r:15px){
	border-radius:  @r;
	-moz-border-radius:  @r;
	-webkit-border-radius:  @r;
}

//  xs font size
.font-xs-t1{
	font-size:22px;
	line-height: 1.3em;
	font-weight: 500;
}
.font-xs-t2{
	font-size: 14px;
	line-height: 160%;
	font-weight: inherit;
	color: #9B9B9B;
	text-align: center;
}
.font_lg_t2{
	font-size: 18px;
	line-height: 160%;
	font-weight: inherit;
	color: #9B9B9B;
	text-align: left;
}

// 自适应 rem setting
//设计视图宽度
@view-base:720;
@font-size-num:100;  //设置100是为了扩大倍数使更精确
@rembase:1rem * @font-size-num;
@pxbase:1px * @font-size-num;
//////自适应配置//////
//(min-width:320px)
@view-xs-font-size:ceil(@pxbase * 320 / @view-base);
//(min-width:480px)
@view-sm-font-size:ceil(@pxbase * 375 / @view-base);
//(min-width:600px)
@view-smp-font-size:ceil(@pxbase * 425 / @view-base);
//(min-width:720)
@view-md-font-size:ceil(@pxbase * 720 / @view-base);

html{
    font-size:@view-md-font-size;
    #root{
    	// 设置 root 根字号为14px
    	font-size: 14px;
    }
}

//精确设置：使用js document.documentElement.style.fontSize = document.documentElement.clientWidth / 7.2 + 'px';
@media (min-width:200px){
  html {
    font-size:@view-xs-font-size;
  }
}
@media (min-width:375px){
  html {
  	font-size: @view-sm-font-size;
  }
}
@media (min-width:425px){
  html {
    font-size: @view-smp-font-size;
  }
}
// 大于720px 以此为基准
@media (min-width:720px) {
  html {
    font-size: @view-md-font-size;
  }
}
// 自适应 rem setting
